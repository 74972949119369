import React, { useCallback, useState } from "react";
import Button from "../../components/Button";
import { putClaimDocuments, uploadClaimDocuments } from "../../lib/AppHelper";
import { inputTypes } from "./inputTypes";
import { toast } from "react-toastify";
import { AppStore } from "../../lib/AppStore";

export const HealthDocuments = ({
  step,
  members,
  prevStep,
  setValue,
  onSubmit,
  value,
  state,
  columns,
  onRemove,
  isMobile,
}) => {
  const [errors, setErrors] = useState({});

  const onClick = useCallback(() => {
    let error = 0;
    columns.map((column) => {
      const { id } = column;
      if (!state[`doc_${id}`] || state[`doc_${id}`].length == 0) {
        error = error + 1;
      }
    });
    setTimeout(() => {
      if (error != columns.length) {
        setErrors({});
        return onSubmit();
      } else {
        toast.error("please upload any one document");
      }
    }, 100);
  }, [errors, columns, state]);
  const handleUpdate = useCallback(
    async (id, file) => {
      await uploadClaimDocuments(id, state.claimId, file).then((res) => {
        if (res.code === 200) {
          setValue({
            [`doc_${id}`]: [
              ...(state[`doc_${id}`] ? state[`doc_${id}`] : []),
              {
                ...res.data.uploaded[0],
                url: res.data.encodedResource,
              },
            ],
          });
        }
      });
    },
    [state]
  );
  const updateDocuments = useCallback(
    async (id, file) => {
      await putClaimDocuments(id, state.claimId, state.subClaimId, file).then(
        (res) => {
          if (res.code === 200) {
            setValue({
              [`doc_${id}`]: res.data.uploaded.map((doc) => {
                return {
                  ...doc.uploaded[0],
                  url: doc.encodedResource,
                };
              }),
            });
          }
        }
      );
    },
    [state]
  );
  return (
    <div>
      <p style={{ fontWeight: "600", color: "#Db2228", marginBottom: "10px" }}>
        Upload claim documents
      </p>
      {columns?.map((column) => {
        const { type, id, details } = column;
        let RenderComponent = inputTypes["file"];
        return (
          <RenderComponent
            key={id}
            label={type}
            error={[errors[id]]}
            details={details}
            onRemove={onRemove}
            field={`doc_${id}`}
            isShowStatus={false}
            attrs={{
              multiple: state.forUpdate ? true : false,
              onChange: (e) => {
                if (state.forUpdate) {
                  updateDocuments(id, e.target.files);
                } else {
                  handleUpdate(id, e.target.files[0]);
                }
              },
              // onBlur: (e) => {
              //   errorCheck([`doc_${id}`]);
              // },
              value: state[`doc_${id}`],
            }}
          />
        );
      })}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <button
          onClick={prevStep}
          className="flex items-center justify-center px-6 py-2 mt-3 border border-transparent text-base rounded-xl text-gray-700 bg-none hover:bg-none active:bg-none focus:outline-none focus:ring-2 focus:ring-primary-200 disabled:text-gray-700 hover:text-primary-500 border border-2 border-red-500"
        >
          Back
        </button>
        <div>
          <Button
            attrs={{
              //   ref: this.BasicBtn,
              type: "button",
              onClick,
            }}
            options={members}
            size="md"
            className={"mt-5"}
            title={"Continue"}
          />
        </div>
      </div>
    </div>
  );
};
