import React, { useState, useEffect } from "react";
import { AppCard, AppHeading, AppRow } from "../../components/AppComponents";
import { Link } from "react-router-dom";
import inventoryImg from "../../images/Layer_1.png";
import AppointmentTable from "./AppointmentTable";
import { Search } from "../Search";
import { ApiGet } from "../../lib/AppHelper";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AppStore } from "../../lib/AppStore";
import { useSelector } from "react-redux";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileArchive,
  faFileExcel,
  faUserMd,
  faUserAstronaut,
  faUserFriends,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { checkReportPermission, reportTypes } from "../../utils/constants";
import reportImage from "../../images/Capa_1.png";

const AiqaCenter = () => {
  const history = useHistory();
  const permissions = useSelector((state) => state?.user?.permissions);
  const [filterValues, setFilterValues] = useState(null);

  useEffect(() => {
    if (filterValues && filterValues.search) {
      AppStore.dispatch({ type: "LOADING", loading: true });
      (async () => {
        const res = await ApiGet("subscriptions", { ...filterValues });
        if (res?.data?.results?.length) {
          history.push({
            pathname: "/subscriptions",
            state: res?.data?.results,
          });
          AppStore.dispatch({ type: "LOADING", loading: false });
        } else {
          AppStore.dispatch({ type: "LOADING", loading: false });
          toast.error("No member found !");
        }
      })();
    }
  }, [filterValues]);

  return (
    <div>
      <AppRow>
        <div className="w-full flex md:flex-row flex-col mb-8">
          <div className=" w-full">
            <Search setValue={setFilterValues} filterValues={filterValues} />
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full">
          {permissions?.application_view ? (
            <div>
              <Link to="/subscriptions">
                <AppCard
                  className="cursor-pointer text-primary-500"
                  title="Active Policy"
                >
                  <AppHeading color="primary">
                    <div className="flex justify-end">
                      <FontAwesomeIcon
                        icon={faUsers}
                        style={{ width: "30px", height: "40px" }}
                      />
                    </div>
                  </AppHeading>
                </AppCard>
              </Link>
            </div>
          ) : (
            false
          )}
          {permissions?.application_add ? (
            <div>
              <Link to="/subscribe">
                <AppCard
                  className="cursor-pointer text-primary-500"
                  title="Add New Member"
                >
                  <AppHeading color="primary">
                    <div className="flex justify-end">
                      <FontAwesomeIcon
                        icon={faUserAstronaut}
                        style={{ width: "30px", height: "40px" }}
                      />
                    </div>
                  </AppHeading>
                </AppCard>
              </Link>
            </div>
          ) : (
            false
          )}

          <div>
            <Link to="/appointment">
              <AppCard
                className="cursor-pointer text-primary-500"
                title="All Appointments"
              >
                <AppHeading color="primary">
                  <div className="flex justify-end">
                    <img
                      src={reportImage}
                      className="h-10 w-10"
                      alt="appointment"
                    />
                  </div>
                </AppHeading>
              </AppCard>
            </Link>
          </div>
          {permissions?.aiqa_center && permissions?.claim_view && (
            <div>
              <Link to="/claims">
                <AppCard
                  className="cursor-pointer text-primary-500"
                  title="Claim"
                >
                  <AppHeading color="primary">
                    <div className="flex justify-end">
                      <FontAwesomeIcon
                        icon={faUserMd}
                        style={{ width: "30px", height: "40px" }}
                      />
                    </div>
                  </AppHeading>
                </AppCard>
              </Link>
            </div>
          )}

          {permissions?.upload_excel ? (
            <div>
              <Link to="/excel-uploader">
                <AppCard
                  className="cursor-pointer text-primary-500"
                  title="Uploader"
                >
                  <AppHeading color="primary">
                    <div className="flex justify-end">
                      <FontAwesomeIcon
                        icon={faFileExcel}
                        style={{ width: "30px", height: "40px" }}
                      />
                    </div>
                  </AppHeading>
                </AppCard>
              </Link>
            </div>
          ) : (
            false
          )}

          {permissions?.group_upload ? (
            <div>
              <Link
                to={{
                  pathname: "/group-excel-uploader",
                  state: { componentTo: "/group-excel-uploader" },
                }}
              >
                <AppCard
                  className="cursor-pointer text-primary-500"
                  title="Group Uploader"
                >
                  <AppHeading color="primary">
                    <div className="flex justify-end">
                      <FontAwesomeIcon
                        icon={faFileExcel}
                        style={{ width: "30px", height: "40px" }}
                      />
                    </div>
                  </AppHeading>
                </AppCard>
              </Link>
            </div>
          ) : (
            false
          )}

          {permissions?.credit_upload ? (
            <div>
              <Link to="/credit-life-uploader">
                <AppCard
                  className="cursor-pointer text-primary-500"
                  title="Credit Life Uploader"
                >
                  <AppHeading color="primary">
                    <div className="flex justify-end">
                      <FontAwesomeIcon
                        icon={faFileExcel}
                        style={{ width: "30px", height: "40px" }}
                      />
                    </div>
                  </AppHeading>
                </AppCard>
              </Link>
            </div>
          ) : (
            false
          )}
          {permissions?.emi_product_uploader ? (
            <div>
              <Link to="/emi-uploader">
                <AppCard
                  className="cursor-pointer text-primary-500"
                  title="EMI Product Uploader"
                >
                  <AppHeading color="primary">
                    <div className="flex justify-end">
                      <FontAwesomeIcon
                        icon={faFileExcel}
                        style={{ width: "30px", height: "40px" }}
                      />
                    </div>
                  </AppHeading>
                </AppCard>
              </Link>
            </div>
          ) : (
            false
          )}
          {permissions?.customer_lead_capture ? (
            <div>
              <Link to="/lead-capture">
                <AppCard
                  className="cursor-pointer text-primary-500"
                  title="Customer Lead Capture"
                >
                  <AppHeading color="primary">
                    <div className="flex justify-end">
                      <FontAwesomeIcon
                        icon={faFileArchive}
                        style={{ width: "30px", height: "40px" }}
                      />
                    </div>
                  </AppHeading>
                </AppCard>
              </Link>
            </div>
          ) : (
            false
          )}

          {permissions?.inventory && (
            <div>
              <Link to="/inventory">
                <AppCard
                  className="cursor-pointer text-primary-500"
                  title="Inventory"
                >
                  <AppHeading color="primary">
                    <div className="flex justify-end">
                      <img
                        src={inventoryImg}
                        className="h-10 w-10"
                        alt="inventoryImg"
                      />
                    </div>
                  </AppHeading>
                </AppCard>
              </Link>
            </div>
          )}
          {permissions?.add_pm_jay_information && (
            <div>
              <Link to="/customer-info">
                <AppCard
                  className="cursor-pointer w-full text-primary-500"
                  title="PM-JAY"
                >
                  <AppHeading color="primary">
                    <div className="flex justify-end">
                      <img
                        src={inventoryImg}
                        className="h-10 w-10"
                        alt="pm-jay"
                      />
                    </div>
                  </AppHeading>
                </AppCard>
              </Link>
            </div>
          )}
          {permissions?.policy_uploader ? (
            <div>
              <Link to="/policy-uploader">
                <AppCard
                  className="cursor-pointer text-primary-500"
                  title="Vendor Policy Uploader"
                >
                  <AppHeading color="primary">
                    <div className="flex justify-end">
                      <FontAwesomeIcon
                        icon={faFileExcel}
                        style={{ width: "30px", height: "40px" }}
                      />
                    </div>
                  </AppHeading>
                </AppCard>
              </Link>
            </div>
          ) : (
            false
          )}
          {checkReportPermission() && (
            <div>
              <Link to="/reports">
                <AppCard
                  className="cursor-pointer text-primary-500"
                  title="Reports"
                >
                  <AppHeading color="primary">
                    <div className="flex justify-end">
                      <img
                        src={reportImage}
                        className="h-10 w-10"
                        alt="reports"
                      />
                    </div>
                  </AppHeading>
                </AppCard>
              </Link>
            </div>
          )}
          {permissions?.reseller_lead_capture ? (
            <div>
              <Link to="/reseller-lead-capture">
                <AppCard
                  className="cursor-pointer text-primary-500"
                  title="Reseller Lead Capture"
                >
                  <AppHeading color="primary">
                    <div className="flex justify-end">
                      <FontAwesomeIcon
                        icon={faFileArchive}
                        style={{ width: "30px", height: "40px" }}
                      />
                    </div>
                  </AppHeading>
                </AppCard>
              </Link>
            </div>
          ) : (
            false
          )}
          {permissions?.unpaid_policy_view ? (
            <div>
              <Link to="/leads">
                <AppCard
                  className="cursor-pointer text-primary-500"
                  title="Unpaid Policy"
                >
                  <AppHeading color="primary">
                    <div className="flex justify-end">
                      <FontAwesomeIcon
                        icon={faUsers}
                        style={{ width: "30px", height: "40px" }}
                      />
                    </div>
                  </AppHeading>
                </AppCard>
              </Link>
            </div>
          ) : (
            false
          )}

          {permissions?.reseller_add ? (
            <div>
              <Link to="/employees">
                <AppCard
                  className="cursor-pointer text-primary-500"
                  title="View Team"
                >
                  <AppHeading color="primary">
                    <div className="flex justify-end">
                      <FontAwesomeIcon
                        icon={faUserFriends}
                        style={{ width: "30px", height: "40px" }}
                      />
                    </div>
                  </AppHeading>
                </AppCard>
              </Link>
            </div>
          ) : (
            false
          )}
        </div>
        <hr className="h-px my-8 bg-red-500 border-0 w-full" />

        <div className="w-full text-xl font-medium text-black pl-3 pt-5 md:pt-0 justify-between items-center flex">
          <div>
            Today’s Appointment{" "}
            <span className="text-base text-red-500">
              ({moment().format("DD/MM/YYYY")})
            </span>
          </div>
        </div>
        <div className="mt-4 w-full ">
          <AppointmentTable id={false} />
        </div>
      </AppRow>
    </div>
  );
};

export default AiqaCenter;
