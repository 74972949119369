import {
  checkUID,
  isValidDrivingLicense,
  isValidPan,
  isValidPassport,
  isValidVoterId,
} from "../../utils/govIdValidation";
import LibertyLogo from "../../images/vendorIcons/Liberty-logo.png";
import AckoLogo from "../../images/vendorIcons/acko-logo.png";
import AdityaBirlaCapitalLogo from "../../images/vendorIcons/aditya-birla-capital-logo.png";
import CareLogo from "../../images/vendorIcons/care-logo.png";

export const govtIds = [
  {
    label: "Select Id Type",
    value: "",
  },
  {
    label: "Aadhar",
    value: "aadhar",
    maxLength: 12,
    regValidation: /\D/,
    frontImg: true,
    backImg: true,
    validateFunction: checkUID,
  },
  {
    label: "Voter ID",
    value: "voter",
    maxLength: 10,
    regValidation: null,
    frontImg: true,
    backImg: false,
    validateFunction: isValidVoterId,
  },
  {
    label: "Passport",
    value: "passport",
    maxLength: 15,
    regValidation: null,
    frontImg: true,
    backImg: true,
    validateFunction: isValidPassport,
  },
  {
    label: "Driving License",
    value: "driving_licence",
    maxLength: 16,
    regValidation: null,
    frontImg: true,
    backImg: false,
    validateFunction: isValidDrivingLicense,
  },
];

export const registrationGovtIds = [
  ...govtIds,
  {
    label: "Pan Card",
    value: "pan",
    maxLength: 10,
    frontImg: true,
    backImg: false,
    regValidation: null,
    validateFunction: isValidPan,
  },
];

export const dateMinus18Years = () => {
  const currentDate = new Date();
  const dateMinus18Years = new Date(currentDate);
  dateMinus18Years.setFullYear(dateMinus18Years.getFullYear() - 18);
  return dateMinus18Years;
};

export const datePlus100Years = () => {
  const currentDate = new Date();
  const datePlus100Years = new Date(currentDate);
  datePlus100Years.setFullYear(datePlus100Years.getFullYear() - 100);
  return datePlus100Years;
};

export const datePlus60Years = () => {
  const currentDate = new Date();
  const datePlus100Years = new Date(currentDate);
  datePlus100Years.setFullYear(datePlus100Years.getFullYear() - 60);
  return datePlus100Years;
};

export const insuranceTableHeaders = [
  {
    name: "Started from",
    key: "createdOn",
    isShown: false,
  },
  {
    name: "Reseller name",
    searchType: "autocomplete",
    regex: /^[a-zA-Z0-9_ ]*$/,
    placeholder: "e.g. (John Doe)",
    key: "resellerName",
    maxLength: 30,
    isShown: true,
  },
  {
    name: "Product name",
    searchType: "input",
    regex: /^[a-zA-Z0-9_ ]*$/,
    placeholder: "e.g. (Apple)",
    key: "productName",
    maxLength: 30,
    isShown: true,
  },
  {
    name: "Customer name",
    searchType: "input",
    regex: /^[a-zA-Z0-9_ ]*$/,
    placeholder: "e.g. (John Brent)",
    key: "customerName",
    maxLength: 30,
    isShown: true,
  },
  {
    name: "Mobile",
    searchType: "input",
    placeholder: "e.g. (9567XXXX45)",
    regex: /^-?\d+$/,
    key: "mobile",
    maxLength: 10,
    isShown: true,
  },
  {
    name: "Insurance member ID",
    searchType: "input",
    regex: /^[a-zA-Z0-9]+$/,
    placeholder: "e.g. (55XXXX67)",
    key: "insuredMemberId",
    maxLength: 16,
    isShown: true,
  },
  {
    name: "Insurance type",
    searchType: "select",
    regex: /^[a-zA-Z0-9]+$/,
    placeholder: "Insurance type",
    key: "insuranceType",
    isShown: true,
  },
  {
    name: "Loan code",
    searchType: "input",
    regex: /^[a-zA-Z0-9_ -\u00A0]*$/,
    placeholder: "e.g. (XXXXX567)",
    key: "loanCode",
    maxLength: 48,
    isShown: true,
  },
  {
    name: "Vendor",
    searchType: "input",
    regex: /^[a-zA-Z]+$/,
    placeholder: "e.g. (Johnson Brent)",
    key: "vendorName",
    maxLength: 30,
    isShown: true,
  },
  {
    name: "Vendor policy ID",
    searchType: "input",
    regex: /^[a-zA-Z0-9]+$/,
    placeholder: "e.g. (HOSXXXX567)",
    key: "vendorPolicyId",
    maxLength: 30,
    isShown: true,
  },
];

export const insuranceType = [
  {
    label: "LAC (LIBERTY)",
    value: "LAC",
  },
  {
    label: "OP (DOC)",
    value: "OP",
  },
  {
    label: "AAC (ACKO)",
    value: "AAC",
  },
  {
    label: "ACH (ACKO)",
    value: "ACH",
  },
  {
    label: "ABHC(ADITYA BIRLA)",
    value: "ABHC",
  },
  {
    label: "CLOAN(CREDIT LIFE)",
    value: "CLOAN",
  },
  {
    label: "LHC(LIBERTY)",
    value: "LHC",
  },
  {
    label: "AC (CARE)",
    value: "AC",
  },
  {
    label: "DOC (DOCTOR CONSULT)",
    value: "DOC",
  },
  {
    label: "SKIN(Dermatologist Consultation)",
    value: "SKIN",
  },
  {
    label: "ABAC(ADITYA BIRLA)",
    value: "ABAC",
  },
  {
    label: "DENT(DENTIST CONSULT)",
    value: "DENT",
  },
  {
    label: "HCH (CARE)",
    value: "HCH",
  },
];

export const vendorListIcon = [
  {
    label: "ABCL",
    value: "ABCL",
    img: AdityaBirlaCapitalLogo,
  },
  {
    label: "ACKO",
    value: "ACKO",
    img: AckoLogo,
  },
  {
    label: "LIBERTY",
    value: "LIBERTY",
    img: LibertyLogo,
  },
  {
    label: "CARE",
    value: "CARE",
    img: CareLogo,
  },
];

export const governmentIdMatch = (governmentIdType) => {
  const multiAadhaar = [
    "aadhaar",
    "aadhaar card",
    "aadhar",
    "aadhar card",
    "adhar",
    "adhar card",
  ];
  if (multiAadhaar.includes(governmentIdType)) {
    return "aadhar";
  }

  const multiVoter = ["voter", "voter card", "voter id", "vt"];
  if (multiVoter.includes(governmentIdType)) {
    return "voter";
  }

  const multiPassport = ["passport"];
  if (multiPassport.includes(governmentIdType)) {
    return "passport";
  }

  const multiDrivingLicense = [
    "driving_licence",
    "driving_license",
    "driving lisence",
  ];
  if (multiDrivingLicense.includes(governmentIdType)) {
    return "driving_licence";
  }
  return "";
};

export const genderMatch = (gender) => {
  switch (gender) {
    case "male":
    case "m":
      return "m";
      break;
    case "female":
    case "f":
      return "f";
      break;
    default:
      return "o";
      break;
  }
};
