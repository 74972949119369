import React, { memo, useEffect, useRef, useState } from "react";
import "rsuite-table/lib/less/index.less";
import moment from "moment";
import { Link } from "react-router-dom";
import { AppStore } from "../../../lib/AppStore";
import Button from "../../../components/Button";
import { truncateString } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faUser,
  faIdCard,
  faPhoneAlt,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";

const SubscriptionsPaginationData = memo(
  ({
    request,
    data,
    limit = 15,
    filterValues = {},
    setAllData = {},
    endPoint,
    renderItem,
    handleGetSearch,
    pageType = "",
    permissions,
  }) => {
    if (limit < 1) {
      limit = 1;
    }
    const lastFilter = useRef(null);
    const [page, setPage] = useState(1);
    const [tableData, setTableData] = useState(data);
    const [errorMessage, setErrorMessage] = useState();
    const nextRef = useRef(null);

    const fetchData = async (pg = null) => {
      nextRef.current = null;
      const start = limit * (pg ? pg - 1 : page - 1);
      if (pg) setPage(pg);
      if (!data) {
        try {
          AppStore.dispatch({ type: "LOADING", loading: true });
          const res = await request(endPoint, {
            limit,
            ...filterValues,
          });

          if (res.status == "error") {
            setErrorMessage(res.message);
          } else {
            handleGetSearch && handleGetSearch(res.data.total);
            if (res.data.results) {
              setTableData(res.data.results);
              setAllData(res.data);
            } else {
              setTableData(res.data);
            }
          }
        } catch (err) {
        } finally {
          AppStore.dispatch({ type: "LOADING", loading: false });
          lastFilter.current = filterValues;
        }
      } else {
        const newData = [...data];
        const update = newData.slice(start, limit * page);
        setTableData(update);
      }
    };

    const handlePrevPage = async () => {
      nextRef.current = nextRef?.current ? nextRef.current - 1 : 0;

      if (tableData && tableData.length > 0) {
        const firstPolicyId = tableData[0].policy_id;

        try {
          AppStore.dispatch({ type: "LOADING", loading: true });
          const res = await request(endPoint, {
            policy_id: firstPolicyId,
            limit,
            short_by: "previous",
            ...filterValues,
          });

          if (res.status == "error") {
            setErrorMessage(res.message);
          } else {
            handleGetSearch && handleGetSearch(res.data.total);
            if (res.data.results) {
              setTableData(res.data.results);
            } else {
              setTableData(res.data);
            }
          }
        } catch (err) {
        } finally {
          AppStore.dispatch({ type: "LOADING", loading: false });
          lastFilter.current = filterValues;
        }
      }
    };

    const handleNextPage = async () => {
      nextRef.current = nextRef?.current ? nextRef.current + 1 : 1;
      if (tableData && tableData.length > 0) {
        const lastPolicyId = tableData[tableData?.length - 1]?.policy_id;

        try {
          AppStore.dispatch({ type: "LOADING", loading: true });
          const res = await request(endPoint, {
            policy_id: lastPolicyId,
            limit,
            short_by: "next",
            ...filterValues,
          });

          if (res.status == "error") {
            setErrorMessage(res.message);
          } else {
            handleGetSearch && handleGetSearch(res.data.total);
            if (res.data.results) {
              setTableData(res.data.results);
            } else {
              setTableData(res.data);
            }
          }
        } catch (err) {
        } finally {
          AppStore.dispatch({ type: "LOADING", loading: false });
          lastFilter.current = filterValues;
        }
      }
    };

    useEffect(() => {
      if (JSON.stringify(lastFilter.current) !== JSON.stringify(filterValues))
        fetchData(1);
      else {
        fetchData();
      }
    }, [setTableData, request, data, filterValues]);

    return (
      <div
        style={{ display: "flex", flexDirection: "column", flex: 1 }}
        className="mt-2"
      >
        {tableData && tableData?.length === 0 ? (
          <div className="text-center">
            <p className="text-xl">You do not have any item</p>
          </div>
        ) : (
          <div className="flex flex-wrap justify-center md:justify-between lg:justify-center">
            {tableData &&
              tableData.length >= 0 &&
              tableData.map((item, index) => {
                if (renderItem) {
                  return renderItem(item);
                }
                return (
                  <div
                    className="my-4 px-1 md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3 sm:w-full"
                    key={index}
                  >
                    <article
                      className={`overflow-hidden rounded-lg shadow-lg ${
                        item?.end_date && moment(item.end_date).isBefore()
                          ? "bg-red-100"
                          : ""
                      }`}
                    >
                      <Link to={`/subscription/${item.application_id}`}>
                        <div>
                          <header className="flex items-center justify-between leading-tight pl-4">
                            <div>
                              <p className="text-lg font-bold capitalize pt-2">
                                {truncateString(
                                  item.customer_name
                                    ? item.customer_name?.toLowerCase()
                                    : item.name?.toLowerCase(),
                                  20,
                                  "Member Empty"
                                )}
                              </p>
                              <p className="text-sm font-bold pt-2">
                                {truncateString(
                                  item.product_display_name
                                    ? item.product_display_name
                                    : item.product_name,
                                  28,
                                  "Product Empty"
                                )}
                              </p>
                            </div>
                            <p className="text-grey-darker text-sm pt-1">
                              <div className="pr-3">
                                <span className="text-green-600">
                                  {moment(
                                    item.start_date
                                      ? item.start_date
                                      : item.application_date
                                  ).format("DD, MMM YYYY")}
                                </span>{" "}
                                <br></br>
                                <span className="text-red-600">
                                  {" "}
                                  {moment(
                                    item.end_date
                                      ? item.end_date
                                      : item.application_date
                                  ).format("DD, MMM YYYY")}
                                </span>
                              </div>
                            </p>
                          </header>

                          <body
                            className={`flex items-center justify-between leading-tight p-4  ${
                              item.stage != "DRAFT" ? "pt-4" : "md:p-3"
                            }`}
                          >
                            <div className="text-sm text-clip overflow-hidden ...">
                              {item?.gov_id_type && item?.gov_id_number && (
                                <p className="flex items-center">
                                  <span
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {/* <FontAwesomeIcon
                                      icon={faIdCard}
                                      className="text-gray-400"
                                    ></FontAwesomeIcon>
                                    &nbsp; */}
                                    {item?.gov_id_type ? item?.gov_id_type : ""}
                                  </span>
                                  &nbsp;:&nbsp;
                                  {typeof item?.gov_id_number === "string"
                                    ? item?.gov_id_number.length > 9
                                      ? new Array(
                                          item?.gov_id_number?.length - 3
                                        ).join("x") +
                                        item?.gov_id_number
                                          ?.substr(
                                            item?.gov_id_number?.length - 4,
                                            4
                                          )
                                          .toUpperCase()
                                      : item?.gov_id_number?.toUpperCase()
                                    : item?.gov_id_number}
                                </p>
                              )}

                              {item?.mobile && (
                                <p className="pt-2 flex items-center">
                                  {/* <FontAwesomeIcon
                                    icon={faPhoneAlt}
                                    className="text-green-500"
                                  ></FontAwesomeIcon>
                                  &nbsp; */}
                                  Phone :&nbsp;
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      width: "250px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    <a
                                      className="no-underline text-black"
                                      href={`tel:+91${item?.mobile}`}
                                    >
                                      {item?.mobile}
                                    </a>
                                  </span>
                                </p>
                              )}
                              {/* <p className="pt-2 flex items-center">
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  className="text-red-500"
                                ></FontAwesomeIcon>
                                &nbsp;
                                Product :&nbsp;
                                {truncateString(
                                  item.product_display_name
                                    ? item.product_display_name
                                    : item.product_name,
                                  30,
                                  "Product Empty"
                                )}
                              </p> */}
                              {item?.loan_code && (
                                <p className="pt-2 flex items-center">
                                  {/* <FontAwesomeIcon
                                    icon={faCreditCard}
                                    className="text-blue-500"
                                  ></FontAwesomeIcon>
                                  &nbsp; */}
                                  Loan&nbsp;Code&nbsp;:&nbsp;
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      width: "250px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {truncateString(item?.loan_code, 20)}
                                  </span>
                                </p>
                              )}
                              {permissions &&
                              permissions?.show_reseller_name_on_member_card
                                ? item?.reseller_name && (
                                    <p className="pt-2 flex items-center capitalize">
                                      {/* <FontAwesomeIcon
                                        icon={faUser}
                                        className="text-indigo-500"
                                      ></FontAwesomeIcon>
                                    &nbsp; */}
                                      Partner Name :&nbsp;
                                      {truncateString(
                                        item?.reseller_name?.toLowerCase(),
                                        18,
                                        "Reseller name empty"
                                      )}
                                    </p>
                                  )
                                : null}
                            </div>
                          </body>
                        </div>
                      </Link>
                    </article>
                  </div>
                );
              })}
          </div>
        )}

        {tableData && tableData.length > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              columnGap: "20px",
              padding: 20,
            }}
          >
            <Button
              attrs={{
                type: "button",
                onClick: handlePrevPage,
                disabled: !nextRef.current,
              }}
              size="sm"
              title={"Previous"}
              className={`px-6 ${!nextRef.current ? "disabled-button" : ""}`}
            />

            <Button
              attrs={{
                type: "button",
                onClick: handleNextPage,
                disabled: tableData?.length < 15,
              }}
              size="sm"
              title={"Next"}
              className={`px-6 ${
                tableData?.length < 15 ? "disabled-button" : ""
              }`}
            />
          </div>
        ) : null}
      </div>
    );
  }
);

export default SubscriptionsPaginationData;
