import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { AppBackButton } from "../components/AppComponents";
import { withRouter } from "react-router";
import Main from "./Main";
import { SelectSubPolicyForm } from "./StepClaimForm/SelectSubPolicyForm";
import { HospitalizationReasonForm } from "./StepClaimForm/HospitalizationReasonForm";
import UserInformation from "./StepClaimForm/UserInformation";
import { BankDetailsForm } from "./StepClaimForm/BankDetailsForm";
import { HealthDocuments } from "./StepClaimForm/HealthDocuments";
import {
  ApiGet,
  ApiPostClientId,
  ApiPutClientId,
  getResellerId,
  removeUploadedFile,
  setError,
  submitClaim,
} from "../lib/AppHelper";
import { SubmittedForm } from "./StepClaimForm/SubmittedForm";
import { AppStore } from "../lib/AppStore";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import Stepper from "../components/common/Stepper";
import ClaimEnglishForm from "../assets/doc/health-claim-form-english.pdf";
import ClaimHindiForm from "../assets/doc/health-claim-form-hindi.pdf";
import { Stack } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const steps = [
  { title: "Personal details" },
  { title: "Claim details" },
  { title: "Bank details" },
  { title: "Medical & other documents" },
];
export class Claim extends Component {
  constructor(props) {
    super(props);
    const {
      policyId,
      memberId,
      claimId = null,
      subClaimId,
      start_date,
    } = this.props.match.params || {};
    this.state = {
      step: 1,
      subPolicyList: [],
      subPolicyIds: [],
      params: {},
      errors: {},
      policyId,
      productId: this.props.location.state?.productId,
      memberId,
      start_date,
      resellerId: getResellerId(),
      hospitalId: null,
      hospitalName: null,
      hospitalCity: null,
      hospitalState: null,
      reason: null,
      claimStartDate: null,
      claimEndDate: null,
      amount: null,
      bankName: null,
      accountName: null,
      accountNumber: null,
      ifscCode: null,
      docsRequired: [],
      claimId: claimId,
      hospitals: [],
      subClaimId,
      productSpecification: null,
      productSpecificationStatus: null,
      maxClaimAmount: null,
      claimSlabType: "",
      dateOfaccident: null,
      dateOfDeath: null,
      accidentCause: null,
      causeOfDeath: null,
      // policyStartDate: this.props.location.state?.start_date || new Date(),
    };
  }
  prevStep = () => {
    const { step } = this.state;
    if (step > 1) {
      this.setState({ step: step - 1 });
    }
  };

  nextStep = () => {
    const { step } = this.state;
    if (step === 1) {
      this.setState({
        hospitalId: null,
        hospitalName: null,
        hospitalCity: null,
        hospitalState: null,
        reason: null,
        claimStartDate: null,
        claimEndDate: null,
        amount: null,
        bankName: null,
        accountName: null,
        accountNumber: null,
        ifscCode: null,
        docsRequired: [],
        errors: {},
        dateOfaccident: null,
        claimSlabType: "",
        dateOfDeath: null,
        isPatientDied: false,
        accidentCause: null,
        causeOfDeath: null,
      });
    }
    this.setState({ step: step + 1 });
  };

  onRemove = (field, docId, index) => {
    removeUploadedFile(`docs/${docId}`).then((res) => {
      if (res.code === 200) {
        let newData = this.state[field];
        newData.splice(index, 1);
        this.setState({ [field]: newData });
      }
    });
  };

  getClaim = async (id) => {
    AppStore.dispatch({ type: "LOADING", loading: true });

    const response = await ApiGet(`claim/${id}`);
    if (response?.status === "success") {
      const {
        policy_id,
        status,
        start_date,
        end_date,
        total_claim_amount,
        claim_reason,
        account_holder_name,
        account_number,
        ifsc_code,
        bank_name,
        insured_member_id,
        hospital_id,
        hospital_name,
        claim_id,
        claim_slab_type,
      } = response.data;
      let jsonofdocs = {};
      if (response.data.documents && response.data.documents.length) {
        jsonofdocs["forUpdate"] = true;
        response.data.documents.map((doc) => {
          const data = jsonofdocs[`doc_${doc.documnet_id_type}`] || [];
          jsonofdocs[`doc_${doc.documnet_id_type}`] = [...data, { ...doc }];
        });
      }
      this.setState({
        step: 2,
        params: {},
        errors: {},
        policyId: policy_id,
        memberId: insured_member_id,
        resellerId: getResellerId(),
        hospitalId: String(hospital_id),
        hospitalName: String(hospital_name),
        reason: claim_reason,
        claimStartDate: start_date,
        claimEndDate: end_date,
        amount: total_claim_amount && Number(total_claim_amount),
        bankName: bank_name,
        accountName: account_holder_name,
        accountNumber: account_number,
        ifscCode: ifsc_code,
        docsRequired: [],
        claimId: claim_id,
        claimSlabType: claim_slab_type,
        status: status,
        ...jsonofdocs,
      });
      AppStore.dispatch({ type: "LOADING", loading: false });
    } else {
      await setError(response.message);
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  componentDidMount() {
    const { claimId = null } = this.props.match.params || {};
    if (claimId) {
      this.getClaim(claimId);
    }
    this.getHospitalData();
    this.resize();
  }

  getHospitalData = async () => {
    const res = await ApiGet("hospitals");
    this.setState({ hospitals: res.data });
  };

  submitStepForm = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const { claimId, subClaimId } = this.props.match.params || {};
    const {
      policyId,
      productId,
      memberId,
      amount,
      reason,
      hospitalId,
      hospitalName,
      hospitalCity,
      hospitalState,
      accountName,
      accountNumber,
      ifscCode,
      claimEndDate,
      claimStartDate,
      subPolicyList,
      resellerId,
      bankName,
      claimSlabType,
      productSpecification,
      productSpecificationStatus,
      isPatientDied = false,
      dateOfaccident,
      dateOfDeath,
      causeOfDeath,
      accidentCause,
    } = this.state;

    const data = {
      policyId,
      memberId,
      amount,
      reason: reason || accidentCause || causeOfDeath || "N/A",
      hospitalId: hospitalId || "6666",
      hospitalName: hospitalId ? hospitalName : "N/A",
      hospitalCity: hospitalId ? hospitalCity : "N/A",
      hospitalState: hospitalId ? hospitalState : "N/A",
      accountName,
      accountNumber,
      ifscCode,
      claimEndDate,
      claimStartDate,
      subPolicyList,
      resellerId,
      bankName,
      claimSlabType,
      productId,
      isPatientDied,
      specification: productSpecification,
      type: productSpecificationStatus?.toUpperCase(),
      deathDate: dateOfDeath,
      causeOfDeath: causeOfDeath,
      accidentDate: dateOfaccident,
    };
    const claimUpdateApi = `claims/${claimId}/sub-claims/${subClaimId}`;
    if (claimId) {
      await ApiPutClientId(claimUpdateApi, data)
        .then((res) => {
          if (res.code === 200) {
            this.setState({
              claimId: res.data.claimId,
              docsRequired: res.data.docsRequired,
            });
            this.nextStep();
          } else if (res.code === 400) {
            setError(res.message);
          }
        })
        .finally(() => {
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
    } else {
      await ApiPostClientId("claims", data)
        .then((res) => {
          if (res.code === 200) {
            this.setState({
              claimId: res.data.claimId,
              docsRequired: res.data.docsRequired,
            });
            this.nextStep();
          } else if (res.code === 400) {
            setError(res.message);
          }
        })
        .finally(() => {
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
    }
  };

  submitClaimForm = () => {
    AppStore.dispatch({ type: "LOADING", loading: true });

    submitClaim(
      `claims/${this.state.claimId}/submit?resellerId=${getResellerId()}`
    )
      .then((res) => {
        if (res.code === 200) {
          this.nextStep();
        }
      })
      .finally(() => {
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
  };

  resize() {
    if (window.innerWidth < 768) {
      this.setState({
        isMobile: true,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {
    const { step, subPolicyList, subPolicyIds, productSpecification } =
      this.state;
    const { claimId = null, subClaimId } = this.props.match.params || {};
    return (
      <Main MessageRef={this.MessageRef}>
        {step <= 5 &&
        step > 1 &&
        ((step >= 1 && !claimId) || (claimId && step >= 2)) ? (
          <div
            onClick={this.prevStep}
            className="flex items-center text-primary pb-3"
            style={{ cursor: "pointer" }}
          >
            <ArrowLeftIcon width={17} className="mr-3" />
            <p>{"Back"}</p>
          </div>
        ) : (
          <AppBackButton to="/">Back to Dashboard</AppBackButton>
        )}

        {step > 1 && this.state.isMobile && step !== 6 && (
          <p className="flex border-b-2 items-center gap-2 justify-center pb-2">
            <span className="flex justify-center text-center border-2 rounded-xl h-8 w-8">
              {step - 1}
            </span>
            {steps[step - 2].title}
          </p>
        )}

        {productSpecification != "CLOAN" &&
          !this.state.isMobile &&
          step > 1 &&
          step !== 6 && <Stepper steps={steps} activeStep={step - 2} />}
        <div
          className={`w-full px-6 md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6`}
        >
          {step === 1 ? (
            <div style={{ width: "100%" }}>
              <SelectSubPolicyForm
                key="1"
                onSubmit={this.nextStep}
                setValue={(value) => this.setState(value)}
                value={subPolicyList}
                subValue={subPolicyIds}
                field={"subPolicyList"}
                subField={"subPolicyIds"}
                state={this.state}
                isMobile={this.state.isMobile}
              />
            </div>
          ) : (
            false
          )}

          {step === 2 ? (
            productSpecification && productSpecification === "CLOAN" ? (
              <>
                <p>
                  Download credit life claim form, fill information and send to
                  customerfirst@careinsurance.com
                </p>
                <div className="flex flex-row">
                  <a
                    href={ClaimEnglishForm}
                    download="claimForm.pdf"
                    className="cursor-pointer text-blue "
                  >
                    {" "}
                    <Stack
                      style={{
                        boxShadow: "1px 0px 15px -1px rgba(0,0,0,0.56)",
                        borderRadius: 10,
                        flexDirection: "row",
                        display: "flex",
                        padding: 20,
                        alignItems: "center",
                        justifyContent: "space-between",
                        margin: 10,
                        cursor: "pointer",
                        borderWidth: 1,
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon
                        className="arrow1"
                        icon={faArrowRight}
                      ></FontAwesomeIcon>
                      claim.pdf in english format
                    </Stack>
                  </a>
                  <a
                    href={ClaimHindiForm}
                    download="claimForm.pdf"
                    className="cursor-pointer text-blue "
                  >
                    {" "}
                    <Stack
                      style={{
                        boxShadow: "1px 0px 15px -1px rgba(0,0,0,0.56)",
                        borderRadius: 10,
                        flexDirection: "row",
                        display: "flex",
                        padding: 20,
                        alignItems: "center",
                        justifyContent: "space-between",
                        margin: 10,
                        cursor: "pointer",
                        borderWidth: 1,
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon
                        className="arrow1"
                        icon={faArrowRight}
                      ></FontAwesomeIcon>
                      claim.pdf in hindi format
                    </Stack>{" "}
                  </a>
                </div>
              </>
            ) : (
              <div
                style={{
                  width: this.state.isMobile ? "100%" : "80%",
                  margin: "auto",
                  marginTop: "30px",
                }}
              >
                <UserInformation
                  onSubmit={this.nextStep}
                  setValue={(value) => this.setState(value)}
                  state={this.state}
                  prevStep={this.prevStep}
                  isMobile={this.state.isMobile}
                  claimId={claimId}
                  subClaimId={subClaimId}
                />
              </div>
            )
          ) : (
            false
          )}
          {/* {step === 2 ? (
            <div
              style={{
                width: this.state.isMobile ? "100%" : "80%",
                margin: "auto",
                marginTop: "30px",
              }}
            >
              <UserInformation
                onSubmit={this.nextStep}
                setValue={(value) => this.setState(value)}
                state={this.state}
                prevStep={this.prevStep}
                isMobile={this.state.isMobile}
              />
            </div>
          ) : (
            false
          )} */}

          {step === 3 ? (
            <div style={{ marginTop: "50px" }}>
              <HospitalizationReasonForm
                key="3"
                onSubmit={this.nextStep}
                setValue={(value) => this.setState(value)}
                state={this.state}
                prevStep={this.prevStep}
                isMobile={this.state.isMobile}
              />
            </div>
          ) : (
            false
          )}

          {step === 4 ? (
            <div style={{ width: "100%", marginTop: "30px" }}>
              <BankDetailsForm
                key="4"
                onSubmit={this.submitStepForm}
                setValue={(value) => this.setState(value)}
                state={this.state}
                prevStep={this.prevStep}
                isMobile={this.state.isMobile}
              />
            </div>
          ) : (
            false
          )}

          {step === 5 ? (
            <div style={{ width: "100%", marginTop: "30px" }}>
              <HealthDocuments
                key="5"
                onSubmit={this.submitClaimForm}
                setValue={(value) => this.setState(value)}
                state={this.state}
                prevStep={this.prevStep}
                columns={this.state.docsRequired}
                onRemove={this.onRemove}
                forUpdate={subClaimId}
              />
            </div>
          ) : (
            false
          )}
          {step === 6 ? (
            <SubmittedForm
              onSubmit={this.nextStep}
              setValue={(value) => this.setState(value)}
              state={this.state}
              value={subPolicyIds}
              prevStep={this.prevStep}
              columns={this.state.docsRequired}
            />
          ) : (
            false
          )}
        </div>
      </Main>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading } = state;
  return {
    loading: loading,
  };
};

const ClaimConnect = connect(mapStateToProps)((props) => {
  return <Claim {...props} />;
});

export default withRouter(ClaimConnect);
