import React, { useEffect, useState } from "react";
import { AppBackButton } from "../../components/AppComponents";
import "./styles.css";
import Popover from "@mui/material/Popover";
// import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { XCircleIcon } from "@heroicons/react/solid";
import { Input, Loader, Pagination, Placeholder, SelectPicker } from "rsuite";
import {
  ApiGet,
  downloadCSVFile,
  getApiClaim,
  getData,
  getResellerId,
} from "../../lib/AppHelper";
import { toast } from "react-toastify";
import Main from "../Main";
import { useSelector } from "react-redux";
import { InputGroup } from "../../components/form/InputGroup";
import Button from "../../components/Button";
import setColor from "../../utils/textColor";

const Inventory = () => {
  // const [medicine, setMedicine] = useState('')
  const [resellerList, setResellerList] = useState([]);
  const [resellerID, setResellerID] = useState(getResellerId());
  const [listLoading, setListLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileData, setFileDate] = useState({
    reports: [],
    headers: [
      { label: "Reseller Name", key: "reseller_name" },
      { label: "Product Name", key: "product_name" },
      { label: "Customer Name", key: "customer_name" },
      { label: "Customer Mobile", key: "customer_mobile" },
      { label: "Policy Start Date", key: "policy_start_date" },
      { label: "Policy End Date", key: "policy_end_date" },
      { label: "Uploaded Date", key: "created_on" },
    ],
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const user = useSelector((state) => state.user);

  const { permissions } = user;
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleChange = (e) => {
  //     setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  // const handleSubmit = (e) => {
  //     e.preventDefault();
  // };

  // const onMedicineChange = (value) => {
  //     setMedicine(value)
  // }

  async function getResellerList() {
    const response = await ApiGet(`team-aiqa-center`);
    if (response.status == "success") {
      let arr = response?.data?.map((item) => ({
        label: item.mobile ? item.name + " (" + item.mobile + ")" : item.name,
        value: item.id,
      }));
      setResellerList(arr);
      setListLoading(false);
    } else {
      setListLoading(false);
      toast.error("Something went wrong");
    }
  }

  const renderResellerList = (menu) => {
    if (listLoading) {
      return (
        <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
          <Loader content="Loading..." />
        </p>
      );
    }
    return menu;
  };

  const handleOnSelect = () => {
    setLoading(true);
    getStockInventoryData(data);
  };
  const downloadReportClick = async () => {
    const request = "";
    const resellerId = getResellerId();
    const fileName = `${resellerId}`;
    const secondsTimestamp = Math.floor(Date.now() / 1000);

    await downloadCSVFile(
      "stock/export/ALL",
      request,
      `${fileName}_${secondsTimestamp}.csv`
    );
  };

  const data = {
    resellerID: resellerID,
    page: activePage ? activePage - 1 : 0,
    size: 10,
    // medicine: medicine
  };

  async function getStockInventoryData(data) {
    const response = await getApiClaim(
      `stock/summary/MEDICINE/${data.resellerID}?page=${data.page}&size=${data.size}`
    );
    if (response.code == 200) {
      setTableData(response?.data?.content);
      setCount(response?.data?.totalElements);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    getResellerList();
  }, []);

  useEffect(() => {
    getStockInventoryData(data);
  }, [activePage]);

  return (
    <Main>
      {permissions && permissions.inventory ? (
        <>
          <div className="space-y-4" style={{ marginTop: "-25px" }}>
            {/* Change the path to aiqaCenter Dashboard */}
            <AppBackButton to="/" />
          </div>
          <div className="grid space-y-4">
            <div
              style={{
                alignItems: "center",
              }}
              className="flex justify-between flex-wrap"
            >
              <div
                className="mr-2 text-sm font-medium text-gray-900"
                style={{
                  display: "flex",
                  marginBottom: "5px",
                }}
              >
                <div className="mr-2 text-lg font-medium text-gray-900">
                  Inventory Stock Details
                </div>
                {permissions && permissions.aiqa_center_head_office ? (
                  <div style={{ marginLeft: "10px" }}>
                    <Button
                      attrs={{
                        disabled: loading,
                        type: "button",
                        onClick: downloadReportClick,
                      }}
                      size="sm"
                      title="Master dump"
                      icon={`data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAACqUlEQVR4nO2cS27UQBRFTQYECAIGSLAb2EhgDRCxhkgMIBJBisQgG4CAGKAeMgF2wGfEjI9E+AXI8ESlVJDVcZXtVvqV2+8eyZN22uW6p57tcseuKiGEEEIIIYQQQgjRBWCp0x+KkwU4C2wCP+PyIHymnI0ANjjOPQmwE/ClQcBXCbAT0IgESIAPSFB6v9yABEiAa1AFSIBrUAVIgGtQBUiAa1AFSIBrUAVIgGtQBUiAa1AFSIBrUAVIgGtQBUiAa1AFSIBrUAVIgGtQBUiAa1AFSIBrUAVIgGtQBSymAGBJT1amn3p8BPwFvgF3geWTEhC2FZ+kDNvfB7aB8z2cjxsOw5/mRUpCHwEx/LCtabbn3rFFgMPDwp9Epo0SugrIhE+sBD3oHQB+p0JtktBFQEv4gT3gVP07bgHuZ4I6JqFNQIfwAxvFOjw06BbYfwk5AX23JSLAaeB5S3AT4ExmfedtHLUravQYvbOsO1qvkZ+DbqN4FjTyC0qY6LBTTsJklOFbTGLodk4oeswPcwnT+YT126qYXcJcwwfOAVvAv7hsmby1q8TbqugvwWLkP2xod3OebebeVvXZoN3ljhJMLjWBHw1tfx/1jyG0n5jNTrjFcigpoEWC6dWOWwG1w9E68Cku69YzXNcChoAEFEYCCiMBhZEAxwLCb6lNuPk3D+BCIoNfFo1/SDR+rXICcD2RwXuLxncSje9UTgCeJTJ4bNH4TdKsVSMHuJPp/6rFDlwKN50yO/E0luhKNRKAldin1MgP7AIXhzAKvHLLJPzavZg3pXs8IF6Fm4RmAqKEq8DH0j0fACGDK6bh1yRcBl7il9dhIBYJf+r+/Fo8CXlhF7htftjJEa4AgBvAE+BdZsa8iOwBb2PfVs2udoQQQgghhBBCCCFENSYOAF/zGQIVdaXOAAAAAElFTkSuQmCC`}
                    />
                  </div>
                ) : null}
              </div>
              {permissions && permissions.show_team_inventory ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <SelectPicker
                    value={resellerID}
                    placeholder={"Select Inventory ..."}
                    data={resellerList || []}
                    onSelect={(e) => {
                      setResellerID(e);
                    }}
                    onClean={() => {
                      setResellerID(getResellerId());
                    }}
                    style={{
                      width: "245px",
                      color: "black",
                      opacity: "0 !important",
                      backgroundColor: "white",
                    }}
                    renderMenu={renderResellerList}
                  />
                  {/* <button
                    className="float-right ml-4 sm-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-1 px-3 border border-red-500 hover:border-transparent rounded "
                    onClick={handleOnSelect}
                  >
                    Search
                  </button> */}
                  <div style={{ marginLeft: "10px" }}>
                    <Button
                      attrs={{
                        disabled: loading,
                        type: "button",
                        onClick: handleOnSelect,
                      }}
                      size="sm"
                      title="Search"
                    />
                  </div>
                </div>
              ) : (
                false
              )}
            </div>

            <div className="w-full overflow-x-scroll">
              <table className="w-full table-auto border-collapse">
                <thead>
                  <tr className="h-14">
                    <th className=" bg-red text-white text-center">
                      Medicine Code
                    </th>
                    <th className=" bg-red text-white text-center">
                      Medicine Name
                    </th>
                    <th className=" bg-red text-white text-center">
                      Current Stock
                    </th>
                  </tr>
                </thead>
                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan={3} className="w-full bg-white text-center">
                        <Placeholder.Paragraph
                          rows={15}
                          rowHeight={8}
                          rowMargin={10}
                        />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {tableData && tableData.length > 0
                      ? tableData.map((item, index) => {
                          return (
                            <tr className="border-2" key={index}>
                              <td className="flex justify-center items-center">
                                <div
                                  className="pl-4 pr-4"
                                  style={{
                                    backgroundColor: item?.productHexcode
                                      ? item?.productHexcode
                                      : "#db2228",
                                    color: item.productHexcode
                                      ? setColor(item?.productHexcode)
                                      : "",
                                  }}
                                >
                                  {item?.sku ? item?.sku : "----"}
                                </div>
                              </td>
                              <td className="bg-white text-center border border-black-300">
                                {item?.productName ? item?.productName : "----"}
                              </td>
                              <td className="bg-white text-center border border-black-300">
                                {item?.balance ? item?.balance : "----"}
                              </td>
                            </tr>
                          );
                        })
                      : tableData &&
                        tableData.length == 0 && (
                          <td
                            colspan={3}
                            className="w-full bg-white text-center"
                          >
                            <h5
                              style={{
                                marginTop: 60,
                                height: "100px",
                                fontSize: "20px",
                                textAlign: "center",
                              }}
                            >
                              No Data Found
                            </h5>
                          </td>
                        )}
                  </tbody>
                )}
              </table>
            </div>
            <div className="mt-3">
              <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={4}
                size="xs"
                layout={["total", "-", "pager", "skip"]}
                total={count}
                limit={10}
                activePage={activePage}
                onChangePage={setActivePage}
              />
            </div>
          </div>
          <div
            className="flex flex-row-reverse"
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              zIndex: 10,
              margin: "40px",
            }}
          >
            {/* This button is hidden due to change in requirement */}
            {/* <div>
              <Button
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
                style={{
                  backgroundColor: "#db2228",
                  color: "white",
                  visibility: "hidden",
                }}
              >
                Raise Query
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    position: "sticky",
                    top: 0,
                    right: 0,
                    zIndex: "111",
                    display: "flex",
                    flexDirection: "row-reverse",
                    border: "1px",
                    borderColor: "#db2228",
                    cursor: "pointer",
                  }}
                >
                  <XCircleIcon
                    className="block h-6 w-6"
                    aria-hidden="false"
                    color="#db2228"
                    onClick={handleClose}
                  />
                </div>
                <div
                  style={{
                    backgroundColor: "#FFFFFF",
                    width: "350px",
                    height: "400px",
                    top: "368px",
                    left: "829px",
                    borderRadius: "37px",
                    border: "1px",
                  }}
                >
                  <Typography
                    style={{
                      color: "#db2228",
                      marginLeft: "20px",
                      fontWeight: "650",
                    }}
                  >
                    Raise a Query Here
                  </Typography>
                  <form
                    className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96"
                    style={{ marginTop: "20px", marginLeft: "10px" }}
                  >
                    <div className="mb-1 flex flex-col gap-6">
                      <Typography variant="h8" color="red" className="-mb-1">
                        Email
                      </Typography>
                      <Input
                        size="lg"
                        placeholder="Email"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                      <Typography variant="h8" color="red" className="-mb-3">
                        Phone Number
                      </Typography>
                      <Input
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                      <Typography
                        variant="h8"
                        color="red"
                        className="-mb-3"
                        style={{ fontFamily: "poppins" }}
                      >
                        Raise Query
                      </Typography>
                      <Input
                        size="lg"
                        placeholder="Your query here..."
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                  </form>
                </div>
              </Popover>
            </div> */}
          </div>
        </>
      ) : (
        <div>
          <AppBackButton to="/">Back</AppBackButton>
          <div className="text-red-500">
            <h4>You don't have permission to view inventory</h4>
          </div>
        </div>
      )}
    </Main>
  );
};

export default Inventory;
