import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyCheck } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const PaymentDetails = ({ claim }) => {
  // Helper function to truncate text
  const truncateText = (text, length = 20) => {
    return text && text.length > length
      ? text.substring(0, length) + "..."
      : text;
  };

  return (
    <div
      id="style-1"
      style={{
        width: "350px",
        height: "350px",
        borderRadius: "10px",
        padding: "20px 20px",
        boxShadow:
          "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
        overflow: "auto",
        overflowX: "hidden",
      }}
    >
      <span
        style={{
          fontSize: "14px",
          color: "#DB2228",
          fontWeight: "600",
          textAlign: "center",
          display: "block",
        }}
      >
        Payment details
      </span>
      <hr />
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <FontAwesomeIcon
          icon={faMoneyCheck}
          style={{ fontSize: "50px", color: "#Db2228" }}
        />
      </div>
      <div className="pt-4 text-sm">
        {claim?.account_holder_name && (
          <div className="pb-2 w-100">
            <span className="font-semibold">Account holder name : </span>
            <span title={claim?.account_holder_name || "---"}>
              {truncateText(claim?.account_holder_name) || "---"}
            </span>
          </div>
        )}
        {claim?.account_number && (
          <div className="pb-2">
            <span className="font-semibold">Account number :</span>{" "}
            <span title={claim?.account_number || "---"}>
              {truncateText(claim?.account_number) || "---"}
            </span>
          </div>
        )}
        {claim?.bank_name && (
          <div className="pb-2">
            <span className="font-semibold">Bank name :</span>{" "}
            <span title={claim?.bank_name || "---"}>
              {truncateText(claim?.bank_name) || "---"}
            </span>
          </div>
        )}
        {claim?.member?.payment_status && (
          <div className="pb-2">
            <span className="font-semibold">Payment status:</span>{" "}
            <span title={claim?.member?.payment_status || "---"}>
              {truncateText(claim?.member?.payment_status) || "---"}
            </span>
          </div>
        )}
        {claim?.sub_claims?.[0]?.transaction_date && (
          <div className="pb-2">
            <span className="font-semibold">Transaction Date :</span>{" "}
            {claim?.sub_claims?.[0]?.transaction_date
              ? moment(claim?.sub_claims?.[0]?.transaction_date).format(
                  "DD MMM YYYY"
                )
              : "---"}
          </div>
        )}
        {claim?.claim_summary?.[0]?.transaction_number && (
          <div className="pb-1">
            <span className="font-semibold">Transaction Number :</span>{" "}
            <span
              title={claim?.claim_summary?.[0]?.transaction_number || "---"}
            >
              {truncateText(claim?.claim_summary?.[0]?.transaction_number) ||
                "---"}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentDetails;
