import React from "react";
import { useSelector } from "react-redux";
import { Placeholder } from "rsuite";
import { Label } from "./constant";

const Cards = ({
  label,
  newCount,
  totalCount,
  setFilter,
  filter,
  windowSize,
}) => {
  if (!Label[label]) return;
  return (
    <div
      style={{
        height: "60px",
        width: windowSize[0] < 800 ? "140px" : "200px",
        cursor: "pointer",
        borderRadius: "10px",
        border: filter === label ? "3px solid #DB2228" : "transparent",
        boxShadow: `${
          filter === label ? "#dfecfb" : "rgba(0, 0, 0, 0.24)"
        } 0px 3px 8px`,
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-evenly",
        position: "relative",
        padding: "0 5px",
        color: filter === label ? "#DB2228" : "black",
      }}
      onClick={() => {
        if (filter === label) {
          // If the same div is clicked, unselect it
          localStorage.removeItem("card_Filter");
          setFilter(null);
        } else {
          // If a new div is clicked, select it
          localStorage.setItem("card_Filter", label);
          setFilter(label);
        }
      }}
    >
      <span
        style={{
          fontSize: "14px",
          fontWeight: "600",
          textTransform: "capitalize",
        }}
      >
        {Label[label]}
      </span>
      <span style={{ fontSize: "16px", fontWeight: "600" }}>{totalCount}</span>
      {newCount > 0 &&
        (label === "SETTLED" || label === "REJECTED" || label === "DRAFT") && (
          <span
            style={{
              height: "40px",
              width: "40px",
              background: "#Db2228",
              border: "1px soild #DB2228",
              color: "white",
              display: "grid",
              placeItems: "center",
              borderRadius: "50%",
              position: "absolute",
              top: -15,
              right: -15,
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            +{newCount}
          </span>
        )}
    </div>
  );
};

export default Cards;
