import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import { AppStore } from "../../lib/AppStore";
import Main from "../Main";
import {
  ACTION_CLAIM_STATUS,
  ApiGet,
  downloadCSVFileForClaimPoint,
  getResellerId,
  postApiClaim,
} from "../../lib/AppHelper";
import moment from "moment";
import { InputSelect } from "../../components/form/InputSelect";
import Button from "../../components/Button";
import InputDate from "../../components/form/InputDate";
import { Pagination } from "rsuite";
import { RefreshIcon } from "@heroicons/react/solid";
import { SwapVert } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const ActionClaimReport = (props) => {
  const [state, setState] = useState({
    total: 0,
    limit: 10,
    layout: ["total", "-", "pager", "skip"],
    reports: [],
    params: {
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      status: "ALL",
      page: 1,
      size: 10,
      resellerId: getResellerId(),
      actionDateSorting: "DESC",
    },
    employees: [],
    toMaxDate: new Date(),
  });

  const onChangeHandler = (e, key) => {
    const { params } = state;
    params[key] = e.target.value;
    setState((prev) => ({ ...prev, params: params }));
  };

  const onChangeHandlerDate = (e, key) => {
    const { params } = state;
    if (key === "fromDate") {
      let date = new Date(e).toISOString().split("T")[0];
      const date1 = new Date(date);
      const date2 = new Date(params.toDate);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 31) {
        let todate = new Date(moment(date1).add(30, "d"))
          .toISOString()
          .split("T")[0];
        params["toDate"] = todate;
        let todateNew = new Date(todate);
        setState((prev) => ({ ...prev, toMaxDate: todateNew }));
      }
    }
    params[key] = new Date(e).toISOString().split("T")[0];
    setState((prev) => ({ ...prev, params: params }));
  };

  const handleClickReset = () => {
    const { params } = state;
    params["toDate"] = moment().format("YYYY-MM-DD");
    params["fromDate"] = moment().format("YYYY-MM-DD");
    params["resellerId"] = getResellerId();
    params["status"] = "ALL";
    setState((prev) => ({ ...prev, toMaxDate: new Date(), params: params }));
  };

  useEffect(() => {
    getReports(state?.params);
    getTeam();
  }, []);

  const handleClick = () => {
    onPageChange(1);
  };

  const onPageChange = (page) => {
    if (page === false) return false;
    setState((prev) => {
      const options = { ...prev, params: { ...prev.params, page: page } };
      getReports(options.params);
      return options;
    });
  };

  const getTeam = async () => {
    const response = await ApiGet(`team`);
    if (response.status === "success") {
      setState((prev) => ({ ...prev, employees: response.data }));
    }
  };

  const downloadClick = async () => {
    let payload = {
      fromDate: state.params?.fromDate,
      toDate: state.params?.toDate,
      status: state.params?.status,
      resellerId: state.params?.resellerId,
    };
    AppStore.dispatch({ type: "LOADING", loading: true });
    await downloadCSVFileForClaimPoint(
      "download-report-by-action-date",
      payload,
      "claimReport.csv"
    );
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  const getReports = async (request) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const payload = { ...request, page: request.page - 1 };
    const response = await postApiClaim("report-by-action-date", payload);

    if (response?.code === 200) {
      if (response?.data) {
        const items = Object.values(response.data);
        setState((prev) => ({
          ...prev,
          reports: items,
          total: response?.count,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          reports: [],
          total: 0,
        }));
      }
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  const dateSortHandle = (e) => {
    if (state.params.actionDateSorting === "ASC") {
      setState((prev) => {
        const para = { ...prev.params, actionDateSorting: "DESC" };
        const option = {
          ...prev,
          params: para,
        };
        getReports(para);
        return option;
      });
    } else {
      setState((prev) => {
        const para = { ...prev.params, actionDateSorting: "ASC" };
        const option = {
          ...prev,
          params: para,
        };
        getReports(para);
        return option;
      });
    }
  };

  const { params, reports, employees } = state;
  const { user } = props;
  const { permissions } = user;
  const messageRef = useRef(null);

  return (
    <Main MessageRef={messageRef}>
      <AppHeading className="text-primary-500 text-center">
        Claim Action Report
      </AppHeading>
      {permissions && permissions?.action_claim_report ? (
        <div>
          <div className="grid md:grid-cols-2 gap-2">
            <div>
              <AppBackButton to="/">Back</AppBackButton>
            </div>
            <div className="text-right">
              <div style={{ maxWidth: "30%" }} className="float-right">
                <Button
                  attrs={{ type: "button", onClick: downloadClick }}
                  size="md"
                  title={"Download"}
                />
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-5 gap-4 justify-center mb-2">
            <div>
              <label className="text-sm text-gray-700">Select Team</label>
              <select
                onChange={(e) => onChangeHandler(e, "resellerId")}
                value={params?.resellerId}
                className="mt-2 focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
              >
                {employees.map((option, index) => {
                  return (
                    <option value={option.id} key={index}>
                      {option.name} ({option.mobile})
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <InputSelect
                requiredMark=""
                value={params.status}
                attrs={{
                  value: params.status,
                  onChange: (e) => onChangeHandler(e, "status"),
                }}
                name="status"
                label="Claim Type"
                options={ACTION_CLAIM_STATUS}
              />
            </div>
            <div>
              <InputDate
                label="From"
                value={params.fromDate}
                placeholder="from"
                maxDate={new Date(params.toDate)}
                attrs={{
                  onChange: (e) => onChangeHandlerDate(e, "fromDate"),
                  value: new Date(params.fromDate),
                }}
              />
            </div>
            <div>
              <InputDate
                label="To"
                placeholder="To"
                minDate={new Date(params.fromDate)}
                maxDate={new Date()}
                attrs={{
                  onChange: (e) => onChangeHandlerDate(e, "toDate"),
                  value: new Date(params?.toDate),
                }}
              />
            </div>

            <div className="flex justify-around items-center mt-3">
              <button
                onClick={handleClickReset}
                className="text-white p-1 hover:bg-primary-700 bg-red-500 border-0 rounded-lg"
              >
                <RefreshIcon width={25} className="m-1" />
              </button>

              <Button
                className=""
                attrs={{ type: "button", onClick: handleClick }}
                size="md"
                title={"Search"}
              />
            </div>
          </div>

          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <table className="min-w-full border" id="table-to-xls">
                <thead className="border-b">
                  <tr>
                    <th className=" text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                      Name
                    </th>
                    <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                      Policy Start Date
                    </th>
                    <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                      Claim Register Date
                    </th>
                    <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                      Action Date
                      <Tooltip
                        title={
                          params.actionDateSorting === "ASC"
                            ? "ascending"
                            : "descending"
                        }
                      >
                        <SwapVert
                          className="cursor-pointer"
                          onClick={dateSortHandle}
                        />
                      </Tooltip>
                    </th>
                    <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                      Product
                    </th>

                    <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                      Total Days
                    </th>
                    <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                      Claim Amount
                    </th>
                    <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                      Approved Amount
                    </th>
                    <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                      UTR Number
                    </th>
                    <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                      Status
                    </th>
                  </tr>
                </thead>
                {reports.map((record, index) => {
                  return (
                    <tbody key={index}>
                      <tr
                        className={`border-b ${
                          record.status?.toUpperCase() == "SETTELED"
                            ? "bg-green-100"
                            : ""
                        }
                          ${
                            record.status?.toUpperCase() == "REJECT"
                              ? "bg-red-100"
                              : ""
                          }
                          ${
                            record.status?.toUpperCase() == "QUERY"
                              ? "bg-yellow-100"
                              : ""
                          }`}
                        key={index}
                      >
                        <td className="text-sm capitalize text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {record.name}
                        </td>
                        <td className="text-sm  font-bigger px-6 py-4 whitespace-nowrap">
                          {record?.policyStartDate &&
                            moment(record.policyStartDate).format(
                              "DD, MMM YYYY"
                            )}
                        </td>
                        <td className="text-sm  font-bigger px-6 py-4 whitespace-nowrap">
                          {record?.claimRegisterDate &&
                            moment(record.claimRegisterDate).format(
                              "DD, MMM YYYY"
                            )}
                        </td>
                        <td className="text-sm  font-bigger px-6 py-4 whitespace-nowrap">
                          {record?.actionDate &&
                            moment(record.actionDate).format("DD, MMM YYYY")}
                        </td>

                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {record.productName}
                        </td>

                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {record.noOfDays}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {record.claimAmount}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {record.totalClaimApproved}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {record.utrNumber ? record.utrNumber : "NA"}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {record.status}
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
                {reports.length === 0 ? (
                  <tr>
                    <td
                      colSpan="9"
                      height="50"
                      className="text-red-500 font-light text-center"
                    >
                      Your result is empty
                    </td>
                  </tr>
                ) : (
                  false
                )}
              </table>
            </div>
          </div>

          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={2}
              size="xs"
              layout={state.layout}
              total={state.total}
              limit={state.limit}
              activePage={state.params.page}
              onChangePage={onPageChange}
            />
          </div>
        </div>
      ) : (
        <div>
          <AppBackButton to="/">Back</AppBackButton>
          <div className="text-red-500">
            <h4>You do not have permission to view claim report</h4>
          </div>
        </div>
      )}
    </Main>
  );
};

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

const ResellerClaimReportConnect = connect(mapStateToProps)((props) => {
  return <ActionClaimReport {...props} />;
});

export default withRouter(ResellerClaimReportConnect);
