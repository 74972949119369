import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import { AppBackButton, AppHeading } from "../components/AppComponents";
import { ApiGet, getData } from "../lib/AppHelper";
import Main from "./Main";
import SubscriptionsPaginationData from "./admin/components/SubscriptionPaginationData";
import { Search } from "./Search";

export const Subscriptions = ({ loading }) => {
  const history = useHistory();
  const MessageRef = React.useRef();
  const [filterValues, setFilterValues] = useState(null);
  const [permissions, setPermissions] = useState();
  const [getSearch, setGetSearch] = useState(false);
  const data = history?.location?.state;

  useEffect(() => {
    (async () => {
      const permission = await getData("permissions");
      if (permission && permission.application_view) {
        setPermissions(permission);
      }
    })();
    if (filterValues) {
      history.replace();
    }
  }, [filterValues]);
  const handleGetSearch = useCallback((e) => {
    if (e > 0) setGetSearch(false);
    else setGetSearch(true);
  }, []);

  return (
    <Main MessageRef={MessageRef}>
      <div className="w-full flex md:flex-row flex-col">
        <div className="w-96">
          <AppBackButton to="/">Back to Dashboard</AppBackButton>
        </div>
        {permissions && permissions.application_view ? (
          <div className="w-full">
            <Search setValue={setFilterValues} filterValues={filterValues} />
          </div>
        ) : (
          false
        )}
      </div>

      {permissions && permissions.application_view ? (
        <div className="w-full flex flex-col space-y-4">
          <AppHeading className="pl-3 pt-5 md:pt-0">Active Policies</AppHeading>
          <div className="flex flex-wrap">
            <SubscriptionsPaginationData
              data={data}
              request={ApiGet}
              endPoint={"subscriptions"}
              filterValues={filterValues}
              handleGetSearch={handleGetSearch}
              permissions={permissions}
            />
          </div>
          <Link
            to={{
              pathname: "/subscribe",
              state: { ...filterValues },
            }}
            style={{ marginLeft: 10, marginRight: 10 }}
          >
            {permissions?.hide_application_add ? (
              false
            ) : getSearch && permissions?.aiqa_center ? (
              <button className="w-32 shadow whitespace-nowrap flex items-center justify-center ml-2 px-4 py-2 border border-transparent text base rounded-xl border-primary text-primary bg-primary-50 hover:text-white hover:bg-primary active:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                Add Member
              </button>
            ) : (
              false
            )}
          </Link>
        </div>
      ) : (
        <div className="p-10 text-red-500">
          <h4>You do not have permission to view members</h4>
        </div>
      )}
    </Main>
  );
};

const mapStateToProps = (state) => {
  const { loading } = state;
  return { loading: loading };
};

const SubscriptionsConnect = connect(mapStateToProps)((props) => {
  return <Subscriptions {...props} />;
});

export default withRouter(SubscriptionsConnect);
