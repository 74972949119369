import { createStore } from "redux";

const INITIAL_STATE = {
  user: null,
  token: null,
  loading: false,
  loading_count: 0,
  progress: 0,
  products: [],
  productLoading: true,
  reseller_id: null,
  backdrop: false,
  appointmentLoading: false,
  activeProfile: localStorage.getItem("switchProfile") || "reseller",
  isSuperAdmin: false,
};

function AppReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SIGN_IN":
      return {
        ...state,
        token: action.token,
        user: action.user,
      };
    case "USER_UPDATED":
      return {
        ...state,
        user: action.user,
      };
    case "PRODUCTS":
      return {
        ...state,
        products: action.products,
        productLoading: action.productLoading,
      };
    case "SIGN_OUT":
      return INITIAL_STATE;
    case "BACKDROP":
      return {
        ...state,
        backdrop: action.backdrop,
      };
    case "LOADING":
      let status = action.loading;
      let count = action.loading
        ? state.loading_count + 1
        : state.loading_count - 1;

      if (status === false && count > 0) {
        status = true;
      }

      return {
        ...state,
        loading: status,
        loading_count: count,
      };
    case "PROGRESS":
      return {
        ...state,
        progress: action.progress,
      };
    case "RESELLER_ID":
      return {
        ...state,
        reseller_id: action.reseller_id,
      };
    case "REFRESH_TABLE":
      return {
        ...state,
        appointmentLoading: action.loading,
      };
    case "SWITCH_PROFILE":
      return {
        ...state,
        activeProfile: action.activeProfile,
      };
    case "IS_SUPER_ADMIN":
      return {
        ...state,
        isSuperAdmin: action.isSuperAdmin,
      };
    default:
      return state;
  }
}

export const AppStore = createStore(AppReducer);
