import React, { memo } from "react";
import "rsuite-table/lib/less/index.less";
import { Pagination } from "rsuite";
import { Link } from "react-router-dom";
import {
  ACTION_TOGGLE,
  AMOUNT_TOGGLE,
  TimerCount,
  formatNumber,
  getMessage,
  truncateString,
} from "./constant";
import { ClaimStatus } from "../../../components/AppComponents";
import { Tooltip } from "@mui/material";
import useClaimsPagination from "../hook/useClaimsPagination";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const DataTable = memo(
  ({
    request,
    data,
    limit = 10,
    pagination = true,
    filterValues = {},
    loading,
    endPoint,
    extraData,
    renderItem,
    dataType,
    handleGetSearch,
    pageType = "",
    permissions,
    dateFilter,
    filter,
    setOpen,
    fetchAPIData,
  }) => {
    const {
      tableData,
      totalCount,
      page,
      errorMessage,
      handleChangePage,
      fetchData,
    } = useClaimsPagination({
      request,
      data,
      limit,
      filterValues,
      endPoint,
      extraData,
      handleGetSearch,
      dataType,
      filter,
      dateFilter,
    });

    React.useEffect(() => {
      if (fetchAPIData) {
        fetchAPIData.current = fetchData;
      }
    }, [fetchAPIData, fetchData]);
    return (
      <div
        style={{ display: "flex", flexDirection: "column", flex: 1 }}
        className="mt-2"
      >
        <div className="w-full hidden md:block overflow-scroll no-scrollbar">
          <table className="min-w-full shadow-lg " id="table-to-xls">
            <thead className="border-b-2">
              <tr style={{ backgroundColor: "#db2228", color: "#fff" }}>
                <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center ">
                  Claim id
                </th>
                <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left ">
                  Name
                </th>
                <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center ">
                  Status
                </th>
                <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                  Loan code
                </th>
                <th className="text-sm  font-large px-3 py-4 whitespace-nowrap text-center ">
                  Registered date
                </th>
                {AMOUNT_TOGGLE.includes(filter) && (
                  <th className="text-sm  font-large px-3 py-4 whitespace-nowrap text-center ">
                    Claim amount
                  </th>
                )}
                {ACTION_TOGGLE.includes(filter) && (
                  <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center ">
                    Action
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {tableData && tableData.length ? (
                tableData.map((item, index) => {
                  let countTime = TimerCount(item?.claim_register_date);
                  let new_start_date = item.start_date
                    ? moment(item.start_date).format("DD-MM-YYYY")
                    : "";
                  return (
                    <tr className="border-b-2" key={index}>
                      <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center ">
                        <Link
                          to={"/claims/" + item.claim_id}
                          style={{
                            color: "blue",
                            fontWeight: "600",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          {" "}
                          {item?.claim_id}
                        </Link>
                      </td>
                      <td
                        className="text-gray-900 px-6 py-4 whitespace-nowrap text-left flex items-center"
                        style={{ overflowWrap: "break-word" }}
                      >
                        <div className="flex flex-col items-start">
                          <span
                            style={{
                              background: "#DB2228",
                              color: "white",
                              fontWeight: "600",
                              padding: "0px 3px",
                              fontSize: "8px",
                              borderRadius: "2px",
                              display: item?.claim_is_new ? "block" : "none",
                            }}
                          >
                            New
                          </span>
                          <div
                            className="flex items-center text-wrap"
                            style={{
                              marginTop:
                                filter === "DISPUTE"
                                  ? "13px"
                                  : !item?.claim_is_new &&
                                    (item?.claim_status === "IN_PROGRESS" ||
                                      item?.claim_status === "APPROVED")
                                  ? "13px"
                                  : "0px",
                            }}
                          >
                            <Link
                              to={"/claims/" + item.claim_id}
                              className="capitalize"
                              style={{
                                color: "blue",
                                fontWeight: "600",
                                overflowWrap: "break-word",
                              }}
                            >
                              {truncateString(item?.name?.toLowerCase(), 20)}
                            </Link>
                          </div>
                        </div>
                      </td>

                      <td className=" text-gray-900  px-6 py-4 whitespace-nowrap text-center">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div>
                            <ClaimStatus
                              status={item.claim_status}
                              style={{ marginLeft: "5px" }}
                            />
                          </div>
                          <div>
                            {countTime?.[0] !== 0 && (
                              <Tooltip
                                title={
                                  countTime?.length > 0
                                    ? getMessage(countTime[0])
                                    : ""
                                }
                                placement="top"
                                followCursor
                                arrow
                              >
                                <div
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    letterSpacing: "0.075rem",
                                    display:
                                      item?.claim_status === "IN_PROGRESS" ||
                                      item?.claim_status === "APPROVED"
                                        ? "inline-block"
                                        : "none",
                                    border: "1px solid ",
                                    borderColor:
                                      countTime?.[0] > 0 ? "green" : "#db2228",
                                    padding: "2px 5px",
                                    backgroundColor:
                                      countTime?.[0] > 0 ? "green" : "#db2228",
                                    color: "#Db2228",
                                    borderRadius: "4px",
                                    marginTop: "5px",
                                  }}
                                >
                                  <p style={{ color: "white" }}>
                                    {countTime?.length > 0 &&
                                      Math.abs(countTime[0])}{" "}
                                    {countTime?.length > 0 &&
                                      countTime[1].join(" ") + ""}
                                  </p>
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </td>
                      <td className=" text-gray-900  px-6 py-4 whitespace-nowrap text-left ">
                        {formatNumber(item?.loan_code)}
                      </td>
                      <td className=" text-gray-900  px-6 py-4 whitespace-nowrap text-center ">
                        {item?.claim_register_date
                          ? moment(item?.claim_register_date).format(
                              "DD-MM-YYYY"
                            )
                          : "---"}
                      </td>
                      {AMOUNT_TOGGLE.includes(filter) && (
                        <td className=" text-gray-900  px-6 py-4 whitespace-nowrap text-center ">
                          {item?.bill_amount}
                        </td>
                      )}
                      {(filter === "QUERY" || filter === "DRAFT") && (
                        <td className=" text-gray-900  px-6 py-4 whitespace-nowrap text-center ">
                          <Link
                            className="text-xs mr-3 bg-transparent font-semibold hover:text-white py-2 px-4 border border-red-300 hover:border-transparent rounded pb-2 button-hover"
                            to={{
                              pathname: `claimEdit/${item?.claim_id}/${item?.sub_claims[0]?.["sub_claim_id"]}`,
                              state: { start_date: new_start_date },
                            }}
                          >
                            <EditOutlinedIcon fontSize="medium" />
                          </Link>
                        </td>
                      )}
                      {(filter === "REJECTED" || filter === "DISPUTE") && (
                        <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center">
                          <div className="flex flex-col items-center gap-1">
                            <Tooltip
                              title="a new comment is added"
                              placeholder="left"
                              arrow
                            >
                              <span
                                style={{
                                  background: "#DB2228",
                                  color: "white",
                                  fontWeight: "600",
                                  padding: "0px 3px",
                                  fontSize: "8px",
                                  borderRadius: "2px",
                                  width: "80px",

                                  display: !item?.openComment
                                    ? "block"
                                    : "none",
                                }}
                              >
                                New comment
                              </span>
                            </Tooltip>
                            <button
                              className="text-xs mr-3 bg-transparent font-semibold hover:text-white py-2 px-4 border border-red-300 hover:border-transparent rounded pb-2 button-hover icon"
                              style={{ width: "130px" }}
                              onClick={() => setOpen(item)}
                            >
                              {item?.sub_claims[0]?.raised_ticket === "1"
                                ? "ADD COMMENT"
                                : "RAISE DISPUTE"}
                            </button>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan="9"
                    height="50"
                    className="text-red-500 font-light text-center border"
                  >
                    Your result is empty
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="w-full block sm:block md:hidden justify-center">
          {tableData &&
            tableData.length >= 0 &&
            tableData.map((item, index) => {
              if (renderItem) {
                return renderItem(item);
              }
            })}
        </div>
        {pagination && tableData && tableData.length > 0 ? (
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={4}
              size="xs"
              layout={["total", "-", "pager", "skip"]}
              total={totalCount}
              limit={limit}
              activePage={page}
              onChangePage={handleChangePage}
            />
          </div>
        ) : null}
      </div>
    );
  }
);

export default DataTable;
