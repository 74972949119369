import React from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { SubmitImage, GreenTick, WaitingHourGlass } from "../../images";
import File from "../../assets/doc/secure-(personal-accident-insurance-product)---claim-form.pdf";
const staticSubPolicies = [24, 48];
const compareEveryElement = (a, b) => {
  let check = void 0;
  a.forEach((i) => {
    b.forEach((x) => {
      if (i === x) {
        check = i;
      }
    });
  });
  return check;
};
export const SubmittedForm = ({ state, value }) => {
  let renderFormComponent = void 0;

  if (compareEveryElement(staticSubPolicies, value)) {
    renderFormComponent = (
      <h3
        style={{
          // fontFamily: "Manrope",
          fontWeight: 500,
          lineHeight: "155%",
          fontSize: "16px",
          color: "#000",
        }}
      >
        We are here to help you with your claims process.
        <br />
        Email us - <a href="mailto:care@aiqahealth.com">care@aiqahealth.com</a>
        <Button
          attrs={{
            type: "button",
            onClick: () => {
              window.open(File);
            },
          }}
          size="md"
          className={"mt-5"}
          title={"Download Claim Form"}
        />
      </h3>
    );
  }

  return (
    <div>
      <p
        className="text-center w-full m-2 font-bold text-xl "
        style={{ color: "#db2228" }}
      >
        Submitted
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        <img src={SubmitImage} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 40,
          marginBottom: 40,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img src={GreenTick} />
          <h3
            style={{
              // fontFamily: "Manrope",
              fontWeight: 500,
              fontSize: 14,
              lineHeight: "155%",
            }}
          >
            Submitted
          </h3>
        </div>
        <div
          style={{ borderBottom: "1px dashed #000", height: 10, width: "20%" }}
        ></div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img src={WaitingHourGlass} />
          <h3
            style={{
              // fontFamily: "Manrope",
              fontWeight: 500,
              fontSize: 14,
              lineHeight: "155%",
            }}
          >
            In Process
          </h3>
        </div>
        <div
          style={{ borderBottom: "1px dashed #000", height: 10, width: "20%" }}
        ></div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img src={WaitingHourGlass} />
          <h3
            style={{
              // fontFamily: "Manrope",
              fontWeight: 500,
              fontSize: 14,
              lineHeight: "155%",
            }}
          >
            Claim Status
          </h3>
        </div>
      </div>
      <div style={{ display: "grid", placeItems: "center" }}>
        <h3
          style={{
            // fontFamily: "Manrope",
            fontWeight: 500,
            lineHeight: "155%",
            fontSize: "16px",
            color: "#000",
          }}
        >
          Your claim request has been generated
        </h3>
      </div>
      {renderFormComponent}
      <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
        <div style={{ width: "200px" }}>
          <Link to={"/"}>
            <Button
              attrs={{
                //   ref: this.BasicBtn,
                type: "button",
                onClick: () => {},
              }}
              size="md"
              className={"mt-5"}
              title={"Home"}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};
